import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '@vandebron/shared/api/axiosBaseQuery';
import { UsageComparison } from '@vandebron/interfaces/home-profile';
import { ComparisonProfile } from './types';

type GetComparisonProfileArg = {
  consumerId: string;
  shippingAddressId: string;
};

type GetUsageComparisonArg = {
  consumerId: string;
  shippingAddressId: string;
};

type UpdateComparisonProfileArg = GetComparisonProfileArg & {
  comparisonProfile: ComparisonProfile;
};

export const homeProfileApi = createApi({
  reducerPath: 'homeProfile',
  baseQuery: axiosBaseQuery({ baseUrl: '/api' }),
  endpoints: (builder) => ({
    getComparisonProfile: builder.query<ComparisonProfile, GetComparisonProfileArg>({
      query: ({ consumerId, shippingAddressId }) => ({
        url: `/consumers/${consumerId}/usage/${shippingAddressId}/comparisonProfile`,
      }),
    }),
    getUsageComparison: builder.query<UsageComparison, GetUsageComparisonArg>({
      query: ({ consumerId, shippingAddressId }) => ({
        url: `/consumers/${consumerId}/usage/${shippingAddressId}/usageComparison`,
      }),
    }),
    updateComparisonProfile: builder.mutation<ComparisonProfile, UpdateComparisonProfileArg>({
      query: ({ consumerId, shippingAddressId, comparisonProfile }) => ({
        url: `/consumers/${consumerId}/usage/${shippingAddressId}/comparisonProfile`,
        method: 'POST',
        body: comparisonProfile,
      }),
      async onQueryStarted({ consumerId, shippingAddressId, comparisonProfile }, { dispatch, queryFulfilled }) {
        const updateResult = dispatch(
          homeProfileApi.util.updateQueryData('getComparisonProfile', { consumerId, shippingAddressId }, (draft) => {
            Object.assign(draft, comparisonProfile);
          })
        );
        try {
          await queryFulfilled;
        } catch {
          updateResult.undo();
        }
      },
    }),
  }),
});

export const { useGetComparisonProfileQuery, useGetUsageComparisonQuery, useUpdateComparisonProfileMutation } =
  homeProfileApi;
