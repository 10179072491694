export const ActiveAnnualStandardUsageTypes = {
  SAU: 'SAU',
  ExpectedSAU: 'ExpectedSAU',
} as const;

export const PaymentSchemes = {
  Memorial: 'Memorial',
  Fixed: 'Fixed',
  Flexible: 'Flexible',
} as const;

export const ContractTypes = {
  Fixed1Year: 'Fixed1Year',
  Fixed3Year: 'Fixed3Year',
  MarketPriceVariable: 'MarketPriceVariable',
  ModelVariable: 'ModelVariable',
  Variable: 'Variable',
  Unknown: 'Unknown',
} as const;

export const MeterTypes = {
  DUN: 'DUN',
  CVN: 'CVN',
  GV: 'GV',
  DUS: 'DUS',
  SLM: 'SLM',
} as const;

export const EndReasons = {
  Cancelled: 'Cancelled',
  AutomaticRenewal: 'AutomaticRenewal',
  CustomerRenewal: 'CustomerRenewal',
  ProducerSwitch: 'ProducerSwitch',
} as const;
