import React from 'react';
import { Paragraph, Link } from '@vandebron/windmolen';
import { formatNLCurrency } from '@vandebron/shared/utils/prettified';

interface ReferralNotificationProps {
  customerReference: string;
  referralDiscount?: number;
}

export const ReferralNotification = ({ customerReference, referralDiscount }: ReferralNotificationProps) => {
  return (
    <>
      <Paragraph fontSize="body-alt" color="white">
        Deel je nu je persoonlijke vriendenlink met familie, vrienden, collega&#39;s of buren. Stapt er iemand via jouw
        link over op goeie energie Vandebron? Dan ontvangen jullie beiden tijdelijk tot{' '}
        {referralDiscount && <span>{formatNLCurrency(referralDiscount)}&nbsp;</span>} korting op je energierekening.
        Waar wacht je nog op?
      </Paragraph>
      <Link
        href={`https://vandebron.nl/vrienden?referral=${customerReference}&utm_source=mijnvandebron&utm_medium=notification`}
        color="white"
        iconplacement="end"
        variant="text-with-icon"
        icon="arrow-right"
        target="_blank"
      >
        Nodig je vrienden uit
      </Link>
    </>
  );
};
