import { ThunkAction } from 'redux-thunk';
import { EntityActionTypes, fetchEntity, removeEntity } from '@vandebron/shared/actions/entityActions';
import { EstimatedAdvancePayment } from '@vandebron/interfaces';
import { ShippingAddress } from '../services/api/energyConsumers';
import { EnergyDashboardEntityState } from '../reducers';
import { ActiveUsageModeItem } from '../reducers/consumerReducer';
import { SET_ACTIVE_SHIPPING_ADDRESS, SET_ACTIVE_USAGE_MODE } from '.';

export type SetActiveShippingAddress = ReturnType<typeof setActiveShippingAddress>;
export type SetActiveUsageMode = ReturnType<typeof setActiveUsageMode>;
export type ConsumerAction = SetActiveShippingAddress | SetActiveUsageMode;

type ConsumerEntityActions = EntityActionTypes<EnergyDashboardEntityState, string>;

export const getEstimatedAdvancePayment =
  (
    organizationId: string,
    shippingAddressId: string
  ): ThunkAction<Promise<void>, EnergyDashboardEntityState, unknown, ConsumerEntityActions> =>
  async (dispatch) => {
    dispatch(removeEntity('estimatedAdvancePayment'));

    await dispatch(
      fetchEntity<EnergyDashboardEntityState, string, EstimatedAdvancePayment>('estimatedAdvancePayment', {
        url: `/api/consumers/${organizationId}/shippingAddresses/${shippingAddressId}/estimatedAdvancePayment`,
        method: 'GET',
      })
    );
  };

/**
 * @deprecated Has been replaced with `setClusterId` from dashboard reducer
 */
export const setActiveShippingAddress = (shippingAddress: ShippingAddress) => ({
  type: SET_ACTIVE_SHIPPING_ADDRESS,
  shippingAddress,
});

export const setActiveUsageMode = (item: ActiveUsageModeItem) => ({
  type: SET_ACTIVE_USAGE_MODE,
  item,
});
