import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '@vandebron/shared/api/axiosBaseQuery';
import type {
  MeterReadingData,
  MeterReadingRequest,
  MeterReadingSubmission,
  MeterReadingValidationResponse,
} from './types';

type GetReadingsArg = {
  customerReference: string;
  caseId: string;
};

type PostReadingsArg = {
  customerReference: string;
  body: MeterReadingSubmission;
};

type PostValidateArg = {
  submission: MeterReadingRequest[];
};

export const meterReadingsApi = createApi({
  reducerPath: 'meterReadings',
  baseQuery: axiosBaseQuery({ baseUrl: '/api' }),
  endpoints: (builder) => ({
    getReadings: builder.query<MeterReadingData, GetReadingsArg>({
      query: ({ caseId, customerReference }) => ({
        url: `/meterReadings/${customerReference}/readings?caseId=${caseId}`,
      }),
    }),
    postReadings: builder.mutation<void, PostReadingsArg>({
      query: ({ customerReference, body }) => ({
        url: `/meterReadings/${customerReference}/readings`,
        method: 'POST',
        body,
      }),
    }),
    postValidate: builder.mutation<MeterReadingValidationResponse[], PostValidateArg>({
      query: ({ submission }) => ({
        url: '/meterReadings/validate',
        method: 'POST',
        body: submission,
      }),
    }),
  }),
});

export const { useGetReadingsQuery, usePostReadingsMutation, usePostValidateMutation } = meterReadingsApi;
