import { format, isAfter } from 'date-fns';
import * as client from '@vandebron/energy-consumers-client';
import { useAppSelector } from '../../../store';
import { selectCurrentClusterId } from '../../../selectors/dashboardSelectors';
import { useCurrentShippingAddress } from '../../dashboard';
import {
  AdvancePayments,
  Contract,
  ContractDetails,
  ContractPrice,
  EnergyConsumerDetails,
  GreenForecast,
  GreenWindow,
  P4Status,
} from './types';

// For more information about how to use generated clients, see:
//
//   common/contracts/client-generator/README.md
//
// Note that in each hook we validate that the type of the returned DTO matches
// our local type using type annotation. This is important, because it will
// warn us when types change in the backend.

export const useGetGreenForecast = (forecastDate: Date) => {
  const user = useAppSelector((state) => state.entity.user?.data);

  const result = client.useGetGreenForecastQuery(
    user
      ? {
          organizationId: user.organizationId,
          forecastDate: formatDate(forecastDate),
        }
      : client.skipToken
  );
  const data: GreenForecast | undefined = result.data;

  return { ...result, data };
};

export const useGetGreenWindow = (forecastDate: Date) => {
  const user = useAppSelector((state) => state.entity.user?.data);

  const result = client.useGetGreenWindowQuery(
    user
      ? {
          organizationId: user.organizationId,
          forecastDate: formatDate(forecastDate),
          windowSize: '3H',
        }
      : client.skipToken
  );
  const data: GreenWindow | undefined = result.data;

  return { ...result, data };
};

export function useGetEnergyConsumerDetails() {
  const user = useAppSelector((state) => state.entity.user?.data);

  const result = client.useGetDetailsQuery(user ? { organizationId: user.organizationId } : client.skipToken);
  const data: EnergyConsumerDetails | undefined = result.data;

  return { ...result, data };
}

export function useGetContractDetails() {
  const user = useAppSelector((state) => state.entity.user?.data);
  const clusterId = useAppSelector(selectCurrentClusterId);

  const result = client.useGetContractDetailsQuery(
    user && clusterId
      ? {
          organizationId: user.organizationId,
          clusterId: clusterId,
        }
      : client.skipToken
  );

  const data: ContractDetails | undefined = result.data;

  return { ...result, data };
}

export function useGetContractPrices(contract?: Contract) {
  const user = useAppSelector((state) => state.entity.user?.data);

  const result = client.useGetContractPricesQuery(
    user && contract
      ? {
          organizationId: user.organizationId,
          contractId: contract.contractId,
          priceDate: getPriceDate(contract),
        }
      : client.skipToken
  );

  const data: ContractPrice[] | undefined = result.data;

  return { ...result, data };
}

export function useGetAdvancePayments() {
  const user = useAppSelector((state) => state.entity.user?.data);
  const currentShippingAddress = useCurrentShippingAddress();
  const clusterReference = currentShippingAddress?.clusterReference ?? currentShippingAddress?.shippingAddressId;

  const result = client.useGetAdvancePaymentsQuery(
    user && clusterReference
      ? {
          organizationId: user.organizationId,
          clusterReference: clusterReference,
        }
      : client.skipToken
  );

  const data: AdvancePayments | undefined = result.data;

  return { ...result, data };
}

export function useGetP4Status() {
  const user = useAppSelector((state) => state.entity.user?.data);
  const clusterId = useAppSelector(selectCurrentClusterId);
  const currentShippingAddress = useCurrentShippingAddress();
  const isSwitchedOut = currentShippingAddress?.isSwitchedOut ?? false;

  const result = client.useGetP4StatusQuery(
    user && clusterId && currentShippingAddress && !isSwitchedOut
      ? {
          organizationId: user.organizationId,
          clusterId: clusterId,
        }
      : client.skipToken
  );

  const data: P4Status | undefined = result.data;

  return { ...result, data };
}

export function useUpdateP4Status() {
  const user = useAppSelector((state) => state.entity.user?.data);
  const clusterId = useAppSelector(selectCurrentClusterId);
  const [originalMutationFn, result] = client.useUpdateP4StatusMutation();

  const wrappedMutationFn: (p4Status: Required<P4Status>) => ReturnType<typeof originalMutationFn> = (p4Status) => {
    if (!user || !clusterId) {
      // eslint-disable-next-line no-console
      console.error('useUpdateP4Status: organizationId or clusterId are missing');
    }

    return originalMutationFn({
      organizationId: user?.organizationId ?? 'undefined',
      clusterId: clusterId ?? 'undefined',
      setP4StatusDto: {
        p4OptIn: p4Status.p4OptIn,
        p4AnalyticsOptIn: p4Status.p4AnalyticsOptIn,
      },
    });
  };

  return [wrappedMutationFn, result] as const;
}

export const useResendContract = () => {
  const user = useAppSelector((state) => state.entity.user?.data);
  const currentShippingAddress = useCurrentShippingAddress();
  const clusterReference = currentShippingAddress?.clusterReference ?? currentShippingAddress?.shippingAddressId;

  const [trigger, result] = client.useResendContractMutation();

  const wrappedTrigger = () => {
    if (!user || !clusterReference) {
      // eslint-disable-next-line no-console
      console.error('useResendContractMutation: organizationId or clusterReference are missing');
    }

    return trigger({
      organizationId: user?.organizationId ?? 'undefined',
      clusterReference: clusterReference ?? 'undefined',
    });
  };

  return [wrappedTrigger, result] as const;
};

const getPriceDate = (contract: Contract) => {
  const today = new Date();
  const startDate = contract.startDate ? new Date(contract.startDate) : undefined;
  const priceDate = startDate && isAfter(startDate, today) ? startDate : today;
  return formatDate(priceDate);
};

const formatDate = (date: Date) => format(date, 'yyyy-MM-dd');
