import { configureStore, PreloadedState } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { discountsApi } from '@vandebron/discounts-client';
import { energyConsumersApi } from '@vandebron/energy-consumers-client';
import { invoicesApi } from '@vandebron/invoices-client';
import { contractsApi } from './services/api/contracts';
import { meterReadingsApi } from './services/api/meterReadings';
import { usageApi } from './services/api/usage';
import { homeProfileApi } from './services/api/homeProfile';
import { contentfulApi } from './services/contentful';
import rootReducer from './reducers';

const middlewares = [
  discountsApi.middleware,
  energyConsumersApi.middleware,
  invoicesApi.middleware,
  contractsApi.middleware,
  meterReadingsApi.middleware,
  usageApi.middleware,
  contentfulApi.middleware,
  homeProfileApi.middleware,
];

export const createStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
    devTools: true,
    preloadedState,
  });

const store = createStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof createStore>;
type AppDispatch = AppStore['dispatch'];

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
