import { combineReducers } from 'redux';
import { EstimatedAdvancePayment, Generator, GeneratorElectricityContent, EnergySettings } from '@vandebron/interfaces';
import { makeEntityReducer, EntityEntryState, SharedEntityState } from '@vandebron/shared/reducers/entityReducer';
import graphReducer from '@vandebron/shared/reducers/graphReducer';
import { discountsApi } from '@vandebron/discounts-client';
import { energyConsumersApi } from '@vandebron/energy-consumers-client';
import { invoicesApi } from '@vandebron/invoices-client';
import { contractsApi } from '../services/api/contracts';
import { meterReadingsApi } from '../services/api/meterReadings';
import { usageApi } from '../services/api/usage';
import { homeProfileApi } from '../services/api/homeProfile';
import { contentfulApi } from '../services/contentful';
import { SmartChargingStatus } from '../actions/meterReadingActions';
import { StandardUsageAndCosts } from '../components/ConsumptionChart/types';
import { MeterReadingsByMarketSegment } from '../interfaces';
import { consumerReducer } from './consumerReducer';
import { consumptionReducer } from './consumptionReducer';
import dashboardReducer from './dashboardReducer';

export enum EnergyDashboardEntity {
  EstimatedAdvancePayment = 'estimatedAdvancePayment',
  Generator = 'generator',
  GasCompensation = 'gasCompensation',
  SmartChargingStatus = 'smartChargingStatus',
  StandardUsageAndCosts = 'standardUsageAndCosts',
  EnergySettings = 'energySettings',
  HistoricMeterReadings = 'historicMeterReadings',
}

export interface EnergyDashboardEntityState extends SharedEntityState {
  [EnergyDashboardEntity.EstimatedAdvancePayment]?: EntityEntryState<EstimatedAdvancePayment>;
  [EnergyDashboardEntity.Generator]?: EntityEntryState<GeneratorElectricityContent>;
  [EnergyDashboardEntity.GasCompensation]?: EntityEntryState<Generator>;
  [EnergyDashboardEntity.SmartChargingStatus]?: EntityEntryState<SmartChargingStatus>;
  [EnergyDashboardEntity.StandardUsageAndCosts]?: EntityEntryState<StandardUsageAndCosts[]>;
  [EnergyDashboardEntity.EnergySettings]?: EntityEntryState<EnergySettings>;
  [EnergyDashboardEntity.HistoricMeterReadings]?: EntityEntryState<MeterReadingsByMarketSegment[]>;
}

const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  [discountsApi.reducerPath]: discountsApi.reducer,
  [energyConsumersApi.reducerPath]: energyConsumersApi.reducer,
  [invoicesApi.reducerPath]: invoicesApi.reducer,
  [contractsApi.reducerPath]: contractsApi.reducer,
  [meterReadingsApi.reducerPath]: meterReadingsApi.reducer,
  [usageApi.reducerPath]: usageApi.reducer,
  [homeProfileApi.reducerPath]: homeProfileApi.reducer,
  [contentfulApi.reducerPath]: contentfulApi.reducer,
  entity: makeEntityReducer<EnergyDashboardEntityState>({}),
  consumer: consumerReducer,
  consumption: consumptionReducer,
  graph: graphReducer,
});

export default rootReducer;
