import { RootState } from '../store';
import { Connection, ShippingAddress, selectEnergyConsumerDetails } from '../services/api/energyConsumers';

export const selectCurrentClusterId = (state: RootState) => state.dashboard.clusterId;

/**
 * @deprecated Use `useCurrentShippingAddress` from dashboard service instead
 */
export const selectCurrentShippingAddress = (state: RootState): ShippingAddress | undefined => {
  const clusterId = selectCurrentClusterId(state);
  const { shippingAddresses } = selectEnergyConsumerDetails(state) ?? {};
  return shippingAddresses?.find((address) => address.clusterId === clusterId);
};

/**
 * @deprecated Use `useCurrentConnections` from dashboard service instead
 */
export const selectAvailableConnections = (state: RootState): Connection[] => {
  const shippingAddress = selectCurrentShippingAddress(state);
  return shippingAddress?.connections ?? [];
};
