import React, { useMemo } from 'react';
import { Menu, MenuItem } from '@vandebron/shared/components/Menu';
import { ShippingAddress } from '../../services/api/energyConsumers';
import { useCurrentShippingAddress } from '../../services/dashboard';

const EnergyDashboardMenu = () => {
  const shippingAddress = useCurrentShippingAddress();
  const menuItems = useMemo(() => {
    return getMenuOptions(shippingAddress);
  }, [shippingAddress]);

  return <Menu menuItems={menuItems} />;
};

const getMenuOptions = (shippingAddress?: ShippingAddress): MenuItem[] => {
  if (!shippingAddress) {
    return [];
  }

  const { shippingAddressId, isSwitchedOut } = shippingAddress;

  return [
    {
      label: 'Overzicht',
      url: '/',
      search: { shippingAddressId },
      hidden: isSwitchedOut,
    },
    {
      label: 'Verbruik',
      url: '/verbruik',
      search: { shippingAddressId },
    },
    {
      label: 'Financieel',
      url: '/financieel',
      search: { shippingAddressId },
    },
    {
      label: 'Meterstanden',
      url: '/meterstanden',
      search: { shippingAddressId },
    },
    {
      label: 'Contract',
      url: '/contract',
      search: { shippingAddressId },
      hidden: isSwitchedOut,
    },
  ];
};

export default EnergyDashboardMenu;
