import { useSelector } from 'react-redux';
import { selectCurrentClusterId } from '../../selectors/dashboardSelectors';
import { Connection, ShippingAddress, useGetEnergyConsumerDetails } from '../api/energyConsumers';
import { ContractTypes } from '../api/energyConsumers/constants';

export function useCurrentShippingAddress(): ShippingAddress | undefined {
  const { data: energyConsumerDetails } = useGetEnergyConsumerDetails();
  const clusterId = useSelector(selectCurrentClusterId);
  return energyConsumerDetails?.shippingAddresses?.find((address) => address.clusterId === clusterId);
}

export function useCurrentConnections(): Connection[] {
  const shippingAddress = useCurrentShippingAddress();
  return shippingAddress?.connections ?? [];
}

export function useHasDynamicContract(): boolean {
  const connections = useCurrentConnections();
  return connections.some((connection) => connection.contract?.contractType === ContractTypes.MarketPriceVariable);
}
