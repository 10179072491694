import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { authenticate } from '@vandebron/shared/actions/userActions';
import { fetchCustomerDetails } from '@vandebron/shared/actions/customerDetailsActions';
import { KeycloakService } from '@vandebron/shared/keycloak/keycloakService';
import { fetchProductGroupsAndGenerators } from '@vandebron/shared/actions/productsActions';
import { selectUserData } from '@vandebron/shared/selectors/entitySelectors';
import { RootState } from '../store';

// consumer actions
export const SET_ACTIVE_SHIPPING_ADDRESS = 'SET_ACTIVE_SHIPPING_ADDRESS';
export const SET_ACTIVE_USAGE_MODE = 'SET_ACTIVE_USAGE_MODE';
export const SET_INITIAL_CONSUMPTION_PARAMS = 'SET_INITIAL_CONSUMPTION_PARAMS';
export const INITIAL_CONSUMPTION_PARAMS_FETCH_FAILED = 'INITIAL_CONSUMPTION_PARAMS_FETCH_FAILED';

export type SetInitialConsumptionParams = ReturnType<typeof setInitialConsumptionParams>;
type SetInitialConsumptionParamsFailed = ReturnType<typeof setInitialConsumptionParamsFetchFailed>;

export type ConsumptionAction = SetInitialConsumptionParams | SetInitialConsumptionParamsFailed;

export const setInitialConsumptionParams = (mostRecentReadingDate?: Date, hasRecentProduction?: boolean) => ({
  type: SET_INITIAL_CONSUMPTION_PARAMS,
  mostRecentReadingDate: mostRecentReadingDate?.toISOString(),
  hasRecentProduction,
});

export const setInitialConsumptionParamsFetchFailed = () => ({
  type: INITIAL_CONSUMPTION_PARAMS_FETCH_FAILED,
});

export const fetchEnergyDashboardData =
  (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => async (dispatch, getState) => {
    await dispatch(authenticate());

    const keycloakWrapper = KeycloakService.getInstance();
    const redirectToLogin = () => {
      keycloakWrapper.logout();
    };

    const user = selectUserData(getState().entity);

    if (!user) {
      redirectToLogin();

      return;
    }

    const { reference } = user;

    if (!reference) {
      return;
    }

    await dispatch(fetchCustomerDetails(reference));
    await fetchProductGroupsAndGenerators(dispatch, getState);
  };
