import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '@vandebron/shared/api/axiosBaseQuery';
import { MarketSegment, MarketSegments } from '@vandebron/shared/interfaces';
import { withOptionalMarketSegment } from '../utils';
import { ExpectedCosts } from '../../../components/ConsumptionStatus/types';
import { Usage, UsageUnit, Costs } from './types';

interface GetHourlyP4UsageArg {
  /** MongoID */
  consumerId: string;
  connectionId: string;
  /** Format: yyyy-MM-ddTHH:mm:SSS */
  startDateTime: string;
  /** Format: yyyy-MM-ddTHH:mm:SSS */
  endDateTime: string;
  marketSegment: MarketSegment;
}

interface GetDailyP4UsageArg {
  /** MongoID */
  consumerId: string;
  connectionId: string;
  /** Format: yyyy-MM-dd */
  startDate: string;
  /** Format: yyyy-MM-dd */
  endDate: string;
  marketSegment: MarketSegment;
}

interface GetCostsArg {
  organizationId: string;
  connectionIds: string[];
  /** Format: yyyy-MM-dd */
  startDate: string;
  /** Format: yyyy-MM-dd */
  endDate: string;
}

interface GetExpectedCostsArg {
  organizationId: string;
  connectionId: string;
  /** Format: yyyy-MM-dd */
  startDate: string;
  /** Format: yyyy-MM-dd */
  endDate: string;
  /** Needed to distinguish costs in test mode */
  marketSegment: MarketSegment;
}

const addMarketSegment = (usage: Omit<Usage, 'marketSegment'>) => ({
  ...usage,
  marketSegment: getMarketSegment(usage.unit),
});

const getMarketSegment = (unit: UsageUnit): MarketSegment => {
  switch (unit) {
    case 'MTQ':
      return MarketSegments.Gas;
    case 'WH':
      return MarketSegments.Electricity;
  }
};

export const usageApi = createApi({
  reducerPath: 'usage',
  baseQuery: axiosBaseQuery({ baseUrl: '/api' }),
  endpoints: (builder) => ({
    getHourlyP4Usage: builder.query<Usage, GetHourlyP4UsageArg>({
      query: ({ consumerId, connectionId, marketSegment, startDateTime, endDateTime }) => ({
        url: withOptionalMarketSegment(
          `/consumers/${consumerId}/connections/${connectionId}/usage?resolution=Hours&startDateTime=${startDateTime}&endDateTime=${endDateTime}`,
          marketSegment
        ),
      }),
      transformResponse: addMarketSegment,
    }),
    getDailyP4Usage: builder.query<Usage, GetDailyP4UsageArg>({
      query: ({ consumerId, connectionId, marketSegment, startDate, endDate }) => ({
        url: withOptionalMarketSegment(
          `/consumers/${consumerId}/connections/${connectionId}/usage?resolution=Days&startDate=${startDate}&endDate=${endDate}`,
          marketSegment
        ),
      }),
      transformResponse: addMarketSegment,
    }),
    getCosts: builder.query<Costs, GetCostsArg>({
      query: ({ organizationId, connectionIds, startDate, endDate }) => ({
        url: `/v2/consumers/${organizationId}/connections/costs?connectionIds=${connectionIds.toString()}&startDate=${startDate}&endDate=${endDate}`,
      }),
    }),
    getExpectedCosts: builder.query<ExpectedCosts, GetExpectedCostsArg>({
      query: ({ organizationId, connectionId, marketSegment, startDate, endDate }) => ({
        url: withOptionalMarketSegment(
          `/consumers/${organizationId}/connections/${connectionId}/expectedcosts?startDate=${startDate}&endDate=${endDate}&billAsSingle=true`,
          marketSegment
        ),
      }),
    }),
  }),
});

export const { useGetCostsQuery, useGetExpectedCostsQuery } = usageApi;
