/* eslint-disable */

import { ContractDetails, ShippingAddress } from '../services/api/energyConsumers';
import { ContractTypes } from '../services/api/energyConsumers/constants';

export interface RenewalOpportunity {
  openOpportunityId: string;
  showVariableCopy: boolean;
}

export const getRenewalOpportunity = (
  currentContractDetails: ContractDetails,
  currentShippingAddress: ShippingAddress
): RenewalOpportunity | undefined => {
  const { openOpportunityId } = currentContractDetails;
  const { connections } = currentShippingAddress;

  if (!openOpportunityId) {
    return;
  }

  const contract = connections?.[0]?.contract;
  const hasVariableContract = contract?.contractType === ContractTypes.Variable;
  const hasModelContract = contract?.contractType === ContractTypes.ModelVariable;
  const hasSolarContract = contract?.description.toLowerCase().includes('solar');

  if (hasSolarContract) {
    return;
  }

  return {
    openOpportunityId,
    showVariableCopy: hasVariableContract || hasModelContract,
  };
};
