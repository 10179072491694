import { Role } from '@vandebron/interfaces';

interface Config {
  ROLE: Role;
  BASE_PATH: string;
  APP_PAGES: string[];
  SENTRY_DSN: string;
}

const config: Config = {
  ROLE: Role.CONSUMER,
  BASE_PATH: '/thuis',
  APP_PAGES: ['/verbruik/mobile'],
  SENTRY_DSN: 'https://587742d202ba43fa9ceb40100aa004dd@o135382.ingest.sentry.io/4505238037135360', // see http://sentry.io
};

export default config;
