import {
  SET_INITIAL_CONSUMPTION_PARAMS,
  INITIAL_CONSUMPTION_PARAMS_FETCH_FAILED,
  ConsumptionAction,
  SetInitialConsumptionParams,
} from '../actions';

export interface ConsumptionState {
  mostRecentReadingDate?: string;
  hasRecentProduction?: boolean;
  mostRecentReadingDateFetched: boolean;
  mostRecentReadingDateFetchFailed?: boolean;
}

const defaultState: ConsumptionState = {
  mostRecentReadingDateFetched: false,
};

export function consumptionReducer(state = defaultState, action: ConsumptionAction): ConsumptionState {
  switch (action.type) {
    case SET_INITIAL_CONSUMPTION_PARAMS: {
      const { mostRecentReadingDate, hasRecentProduction } = action as SetInitialConsumptionParams;
      return {
        ...state,
        mostRecentReadingDate,
        hasRecentProduction,
        mostRecentReadingDateFetched: true,
        mostRecentReadingDateFetchFailed: false,
      };
    }

    case INITIAL_CONSUMPTION_PARAMS_FETCH_FAILED: {
      return {
        ...state,
        mostRecentReadingDate: undefined,
        hasRecentProduction: undefined,
        mostRecentReadingDateFetched: true,
        mostRecentReadingDateFetchFailed: true,
      };
    }

    default:
      return state;
  }
}
