import { GraphUsageMode } from '@vandebron/interfaces';
import { MarketSegments } from '@vandebron/shared/interfaces';
import { Connection, ShippingAddress } from '../services/api/energyConsumers';
import { SET_ACTIVE_SHIPPING_ADDRESS, SET_ACTIVE_USAGE_MODE } from '../actions';
import { ConsumerAction, SetActiveShippingAddress, SetActiveUsageMode } from '../actions/consumerActions';

const isElectricityConnection = (connection: Connection) => connection.marketSegment === MarketSegments.Electricity;

const getActiveConnectionForAddress = (address: ShippingAddress) =>
  address.connections?.find(isElectricityConnection) || address.connections?.[0];

interface ConsumerState {
  activeConnection?: Connection;
  activeUsageMode: GraphUsageMode;
}

export interface ActiveUsageModeItem {
  type: GraphUsageMode;
  connection?: Connection;
}

export const consumerReducer = (state = {}, action: ConsumerAction): ConsumerState => {
  switch (action.type) {
    case SET_ACTIVE_SHIPPING_ADDRESS: {
      const { shippingAddress } = action as SetActiveShippingAddress;
      const activeConnection = getActiveConnectionForAddress(shippingAddress);

      return {
        ...state,
        activeConnection,
        activeUsageMode: activeConnection.marketSegment,
      };
    }

    case SET_ACTIVE_USAGE_MODE: {
      const { item } = action as SetActiveUsageMode;

      return {
        ...state,
        activeUsageMode: item.type,
        activeConnection: item.connection,
      };
    }

    default:
      return state as ConsumerState;
  }
};
