import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import cmsCache from '@vandebron/shared/services/cmsCache';
import { ContentfulError } from './types';

// Simple "query" function that leverages the actual fetching to the cmsCache in shared.
export const contentfulQuery =
  (): BaseQueryFn<{
    contentType: string;
    resolveLinks?: boolean;
  }> =>
  async ({ contentType, resolveLinks = true }) => {
    try {
      const result = await cmsCache.getEntries({ content_type: contentType }, resolveLinks);
      return { data: result.items.map((item) => item.fields) };
    } catch (e) {
      const error = e as ContentfulError;
      return {
        error: {
          status: error.status,
          data: error.message,
        },
      };
    }
  };
