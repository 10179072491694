import { createSelector } from '@reduxjs/toolkit';
import { energyConsumersApi } from '@vandebron/energy-consumers-client';
import { RootState } from '../../../store';
import { EnergyConsumerDetails, P4Status } from './types';

// This is for legacy purposes mainly, i.e. class-based components that can not use hooks.

/**
 * @deprecated Use the `useGetEnergyConsumerDetails` hook instead.
 */
export const selectEnergyConsumerDetails = createSelector(
  (state: RootState) => state,
  (state: RootState) => state.entity.user?.data,
  (state, user) =>
    user
      ? (energyConsumersApi.endpoints.getDetails.select({ organizationId: user?.organizationId })(state)
          ?.data as EnergyConsumerDetails)
      : undefined
);

export const selectP4Status = createSelector(
  (state: RootState) => state,
  (state: RootState) => state.entity.user?.data,
  (state: RootState) => state.dashboard.clusterId,
  (state, user, clusterId) =>
    user && clusterId
      ? (energyConsumersApi.endpoints.getP4Status.select({
          organizationId: user.organizationId,
          clusterId: clusterId,
        })(state)?.data as P4Status)
      : undefined
);
