import React from 'react';
import { useSelector } from 'react-redux';
import { Notifications as HeaderNotification } from '@vandebron/shared/components/DashboardHeader/Notifications';
import { formatNLCurrency } from '@vandebron/shared/utils/prettified';
import { REFERRER_DISCOUNT } from '@vandebron/shared/constants';
import { selectUserData } from '@vandebron/shared/selectors/entitySelectors';
import { RootState } from '../../../store';
import { useCurrentShippingAddress, useHasDynamicContract } from '../../../services/dashboard';
import { useGetContractDetails } from '../../../services/api/energyConsumers';
import { ContractTypes } from '../../../services/api/energyConsumers/constants';
import { getRenewalOpportunity } from '../../../utils/renewal';
import { ReferralNotification } from './ReferralNotification';
import { RenewalNotification } from './RenewalNotification';

const EnergyDashboardNotification = () => {
  const user = useSelector((state: RootState) => selectUserData(state.entity));
  const currentShippingAddress = useCurrentShippingAddress();
  const hasDynamicContract = useHasDynamicContract();
  const { data: currentContractDetails } = useGetContractDetails();

  if (!user?.reference || !currentContractDetails || !currentShippingAddress) {
    return null;
  }

  const contract = currentShippingAddress?.connections?.[0].contract;
  const hasVariableContract = contract?.contractType === ContractTypes.Variable;
  const hasModelContract = contract?.contractType === ContractTypes.ModelVariable;

  const renewalOpportunity = getRenewalOpportunity(currentContractDetails, currentShippingAddress);

  if (renewalOpportunity) {
    return (
      <HeaderNotification
        title={
          hasVariableContract || hasModelContract ? 'Kies voor zekerheid' : 'Je kunt je tarieven opnieuw vastzetten!'
        }
        type="retention pop-up"
        content={
          <RenewalNotification
            customerReference={user?.reference.toString()}
            opportunityId={renewalOpportunity.openOpportunityId}
            showVariableCopy={hasVariableContract || hasModelContract}
          />
        }
        activeByDefault={true}
      />
    );
  }

  // MS-157 Hiding referral notification for SPOT customers
  if (!hasDynamicContract) {
    return (
      <HeaderNotification
        title={`Bespaar tot ${formatNLCurrency(REFERRER_DISCOUNT)} op je energierekening!`}
        type="referral pop-up"
        content={
          <ReferralNotification customerReference={user.reference.toString()} referralDiscount={REFERRER_DISCOUNT} />
        }
        activeByDefault={true}
      />
    );
  }

  return null;
};

export default EnergyDashboardNotification;
