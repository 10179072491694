import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface DashboardState {
  // Stores a reference to the currently selected shipping address
  clusterId?: string;
}

const initialState: DashboardState = {};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setClusterId: (state, action: PayloadAction<string>) => {
      state.clusterId = action.payload;
    },
  },
});

export const { setClusterId } = dashboardSlice.actions;

export default dashboardSlice.reducer;
