import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { HelmetProvider } from 'react-helmet-async';
import { KeycloakService } from '@vandebron/shared/keycloak/keycloakService';
import store from './store';
import config from './config';
import App from './components/App';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@vandebron/windmolen/dist/index.css';
import 'font-awesome/css/font-awesome.min.css';
import '@vandebron/shared/css/styles.scss';
import '@vandebron/shared/css/base/deprecated.scss';
import './styles.scss';

const renderApp = () => {
  const root = createRoot(document.getElementById('root') as HTMLElement);
  root.render(
    <Provider store={store}>
      <HelmetProvider>
        <BrowserRouter basename={config.BASE_PATH}>
          <CompatRouter>
            <App />
          </CompatRouter>
        </BrowserRouter>
      </HelmetProvider>
    </Provider>
  );
};

const keycloakService = KeycloakService.getInstance();
const isLoginRequired = true;

keycloakService.initializeKeycloak(renderApp, isLoginRequired);
