import { useEffect } from 'react';
import { useLocation } from 'react-router-dom-v5-compat';
import { sendPageViewEvent } from '@vandebron/shared/utils/GTM/analytics';
import { isWindowDefined } from '@vandebron/shared/utils/environment';

const usePageViewEvent = () => {
  const location = useLocation();

  useEffect(() => {
    if (!isWindowDefined()) {
      return;
    }
    sendPageViewEvent({
      url: `${window.location.origin}${location.pathname}${location.search}`,
      title: document.title,
      parameters: Object.fromEntries(new URLSearchParams(location.search)),
      urlWithoutParameters: `${window.location.origin}${location.pathname}`,
    });
  }, [location.pathname, location.search]);
};

export default usePageViewEvent;
