import React, { PropsWithChildren, Suspense, lazy, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom-v5-compat';
import { addDays } from 'date-fns';
import { Loader } from '@vandebron/windmolen';
import sentryService from '@vandebron/shared/services/sentry';
import CookieManager from '@vandebron/shared/utils/cookie-manager';
import {
  selectCustomerDetailsData,
  selectEntityIsFetched,
  selectUserData,
} from '@vandebron/shared/selectors/entitySelectors';
import { DashboardHeader, DashboardProductItem, DashboardTopic } from '@vandebron/shared/components/DashboardHeader';
import { ErrorBoundary } from '@vandebron/shared/components/ErrorBoundary';
import Footer from '@vandebron/shared/components/Footer';
import { ForbiddenMsg } from '@vandebron/shared/components/Messages';
import { EmbeddedChat } from '@vandebron/shared/components/EmbeddedChat';
import { triggerCustomerDetailsDataLayerPush } from '@vandebron/shared/utils/GTM/customerDetailsGTM';
import { Role } from '@vandebron/interfaces';
import { RootState, useAppDispatch } from '../../store';
import { useCurrentShippingAddress } from '../../services/dashboard';
import { useGetEnergyConsumerDetails } from '../../services/api/energyConsumers';
import usePageViewEvent from '../../services/analytics/usePageViewEvent';
import { fetchEnergyDashboardData } from '../../actions';
import config from '../../config';
import { Menu } from '../Menu';
import { EnergyDashboardNotification } from './EnergyDashboardNotification';
import { useSetCurrentShippingAddress, useGetShippingAddresses } from './helpers';
import '@vandebron/shared/css/base/dashboard-input.scss';
import './styles.scss';

if (window?.location && process.env.NODE_ENV !== 'development') {
  sentryService.init(config.SENTRY_DSN, true);
}

const dashboardRoutes: { role: Role; path: string }[] = [
  { role: Role.ADMINISTRATOR, path: '/admin' },
  { role: Role.EV, path: '/onderweg' },
  { role: Role.PRODUCER, path: '/productie' },
];

const OverviewPage = lazy(() => import('../../screens/Overview'));
const Financial = lazy(() => import('../../screens/Financieel'));
const PaymentPlan = lazy(() => import('../../screens/PaymentPlan'));
const Consumption = lazy(() => import('../Consumption'));
const HomeProfile = lazy(() => import('../../screens/HomeProfile'));
const MoveCluster = lazy(() => import('../../screens/MoveCluster'));
const MeterReadings = lazy(() => import('../../screens/MeterReadings'));
const SubmitMeterReadings = lazy(() => import('../../screens/SubmitMeterReadings'));
const ContractPage = lazy(() => import('../../screens/Contract'));
const TerminationFee = lazy(() => import('../../screens/TerminationFee/TerminationFee'));
const MonthlyPaymentOptimizer = lazy(() => import('../../screens/MonthlyPaymentOptimizer/MonthlyPaymentOptimizer'));
const ContractStartDateForm = lazy(() => import('../../screens/ContractStartDateForm/ContractStartDateForm'));

const AuthorizedWrapper = ({ isAuthorized, children }: PropsWithChildren<{ isAuthorized?: boolean }>) =>
  isAuthorized ? <>{children}</> : <ForbiddenMsg />;

const Container = ({ children }: PropsWithChildren<unknown>) => (
  <div className="container-fluid fill-height">{children}</div>
);

const App = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  usePageViewEvent();

  const [isPageOpenedInApp, setIsPageOpenedInApp] = useState(false);
  const hasAnalyticsBeenSent = useRef(false);

  const user = useSelector((state: RootState) => selectUserData(state.entity));
  const isUserLoaded = useSelector((state: RootState) => selectEntityIsFetched(state.entity, 'user'));
  const isAuthorized = user && user.roles.includes(config.ROLE) && !!user.reference;

  const customerDetails = useSelector((state: RootState) => selectCustomerDetailsData(state.entity));

  const { data: energyConsumerDetails, isSuccess: areEnergyConsumerDetailsLoaded } = useGetEnergyConsumerDetails();
  const shippingAddresses = useGetShippingAddresses();
  const currentShippingAddress = useCurrentShippingAddress();
  const setCurrentShippingAddress = useSetCurrentShippingAddress();

  const areCustomerDetailsLoaded = useSelector((state: RootState) =>
    selectEntityIsFetched(state.entity, 'customerDetails')
  );

  const isLoaded = isUserLoaded && areCustomerDetailsLoaded && areEnergyConsumerDetailsLoaded;
  useEffect(() => {
    void dispatch(fetchEnergyDashboardData());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user?.reference) {
      const expiresOn = addDays(new Date(), 30);
      CookieManager.setItem('uid', user.reference, expiresOn);
    }
  }, [user]);

  useEffect(() => {
    setIsPageOpenedInApp(config.APP_PAGES.includes(location.pathname));
  }, [location.pathname]);

  // use a ref to prevent the data layer push from being triggered multiple times
  useEffect(() => {
    if (hasAnalyticsBeenSent.current) {
      return;
    }

    if (isLoaded && customerDetails && user && shippingAddresses) {
      triggerCustomerDetailsDataLayerPush(user, shippingAddresses);
      hasAnalyticsBeenSent.current = true;
    }
  }, [isLoaded, customerDetails, user, shippingAddresses]);

  if (!user) {
    return null;
  }

  if (!isAuthorized) {
    const basePath = `//${window.location.hostname}`;

    for (const route of dashboardRoutes) {
      if (user.roles.includes(route.role)) {
        window.location.replace(`${basePath}${route.path}`);
        return null;
      }
    }
  }

  return (
    <ErrorBoundary>
      <Container>
        {!isPageOpenedInApp && (
          <>
            <DashboardHeader
              // @ts-expect-error props don't match, but this won't result in runtime errors.
              energyConsumerDetails={energyConsumerDetails}
              currentCustomerReference={user?.reference?.toString()}
              currentDashboard={DashboardTopic.Energy}
              findActive={(dashboardProductGroup: DashboardProductItem) =>
                dashboardProductGroup.topic === DashboardTopic.Energy &&
                dashboardProductGroup.key === currentShippingAddress?.shippingAddressId
              }
              handleSelect={setCurrentShippingAddress}
              notification={<EnergyDashboardNotification />}
            />
            <Menu />
          </>
        )}
        <AuthorizedWrapper isAuthorized={isAuthorized}>
          <div className="page-wrapper">
            <Suspense>
              <Routing />
            </Suspense>
          </div>
        </AuthorizedWrapper>
      </Container>
      {!isPageOpenedInApp && <Footer />}
      {customerDetails && user && <EmbeddedChat details={customerDetails} user={user} />}
    </ErrorBoundary>
  );
};

export default App;

const Routing = () => {
  const currentShippingAddress = useCurrentShippingAddress();
  const isSwitchedOut = currentShippingAddress?.isSwitchedOut ?? undefined;

  if (isSwitchedOut === true) {
    return (
      <Routes>
        <Route path="/financieel" element={<Financial />} />
        <Route path="/meterstanden" element={<MeterReadings />} />
        <Route path="/meterstanden/doorgeven" element={<SubmitMeterReadings />} />
        <Route path="/verbruik" element={<Consumption />} />
        <Route path="/verbruik/mobile" element={<Consumption />} />
        <Route path="*" element={<Navigate to="/financieel" />} />
      </Routes>
    );
  }

  if (isSwitchedOut === false) {
    return (
      <Routes>
        <Route path="/" element={<OverviewPage />} />
        <Route path="/verbruik" element={<Consumption />} />
        <Route path="/verbruik/mobile" element={<Consumption />} />
        <Route path="/verbruik/profiel" element={<HomeProfile />} />
        <Route path="/financieel" element={<Financial />} />
        <Route path="/financieel/termijnbedrag-wijzigen" element={<MonthlyPaymentOptimizer />} />
        <Route path="/financieel/betalingsregeling" element={<PaymentPlan />} />
        <Route path="/meterstanden" element={<MeterReadings />} />
        <Route path="/meterstanden/doorgeven" element={<SubmitMeterReadings />} />
        <Route path="/contract" element={<ContractPage />} />
        <Route path="/contract/startdatum-wijzigen" element={<ContractStartDateForm />} />
        <Route path="/contract/opzegvergoeding" element={<TerminationFee />} />
        <Route path="/verhuizen" element={<MoveCluster />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    );
  }

  return <Loader height="80vh" data-testid="energy-dashboard-loader" />;
};
