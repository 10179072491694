import { MarketSegment } from '@vandebron/shared/interfaces';

/**
 * API calls can be mocked during local development using the proxy-server.
 * However, in order to return the correct mock data, it sometimes needs
 * `marketSegment` as an additional query parameter so that it can distinguish
 * between Gas and Electricity connections.
 */
export const withOptionalMarketSegment = (url: string, marketSegment: MarketSegment) => {
  if (import.meta.env.MODE === 'development') {
    return `${url}${url.includes('?') ? '&' : '?'}marketSegment=${marketSegment}`;
  }

  return url;
};
