import { useMemo, useEffect } from 'react';
import { useAppDispatch } from '../../store';
import { ShippingAddress, useGetEnergyConsumerDetails } from '../../services/api/energyConsumers';
import { setClusterId } from '../../reducers/dashboardReducer';
import { setActiveShippingAddress } from '../../actions/consumerActions';

type SetShippingAddressFn = (shippingAddressId?: string) => void;

export const useGetShippingAddresses = (): ShippingAddress[] => {
  const { data: energyConsumerDetails } = useGetEnergyConsumerDetails();
  return useMemo(() => energyConsumerDetails?.shippingAddresses ?? [], [energyConsumerDetails]);
};

export const useSetCurrentShippingAddress = (): SetShippingAddressFn => {
  const dispatch = useAppDispatch();
  const shippingAddresses = useGetShippingAddresses();

  const setShippingAddress = (shippingAddress: ShippingAddress) => {
    dispatch(setClusterId(shippingAddress.clusterId));
    // Deprecated, but still needed for setting the active connnection on which the graphs depend.
    dispatch(setActiveShippingAddress(shippingAddress));
  };

  const findShippingAddress = (id?: string) => {
    const shippingAddress = shippingAddresses.find((address) => address.shippingAddressId === id);
    if (shippingAddress) {
      setShippingAddress(shippingAddress);
      return shippingAddress;
    }
  };

  const setCurrentShippingAddress = (shippingAddressId?: string) => {
    if (!shippingAddresses.length) {
      return;
    }

    const shippingAddressFromArguments = findShippingAddress(shippingAddressId);

    if (shippingAddressFromArguments) {
      return;
    }

    const shippingAddressIdFromUrl = getSearchParam('shippingAddressId', location.search);
    const shippingAddressFromUrl = findShippingAddress(shippingAddressIdFromUrl);

    if (shippingAddressFromUrl) {
      return;
    }

    const firstAvailableShippingAddress =
      shippingAddresses.find((address) => !address.isSwitchedOut) ?? shippingAddresses[0];

    if (firstAvailableShippingAddress) {
      setShippingAddress(firstAvailableShippingAddress);
    }
  };

  useEffect(() => {
    if (shippingAddresses && shippingAddresses.length) {
      setCurrentShippingAddress();
    }
  }, [shippingAddresses]); // eslint-disable-line react-hooks/exhaustive-deps

  return setCurrentShippingAddress;
};

const getSearchParam = (name: string, search: string) => {
  return new URLSearchParams(search).get(name) ?? undefined;
};
