import React from 'react';
import { Paragraph, Link } from '@vandebron/windmolen';
import { getUrl } from '@vandebron/shared/utils/environment';
import { RenewalNotificationProps } from './types';
import './RenewalNotification.scss';

export const RenewalNotification = ({
  customerReference,
  opportunityId,
  showVariableCopy,
}: RenewalNotificationProps) => {
  const renewalHref = getUrl(
    'main',
    `verlengen?customerId=${customerReference}&quoteId=${opportunityId}&utm_source=mijnvandebron&utm_medium=notification`
  );

  const content = showVariableCopy
    ? 'Op dit moment heb je een variabel contract met steeds wisselende tarieven. Zet je tarieven vast en kies voor zekerheid. We hebben een aanbod voor je klaar staan.'
    : 'Binnenkort loopt je contract af. Vind je écht groene energie, de beste service en een goede prijs belangrijk? Kies opnieuw voor Vandebron.';

  return (
    <>
      <div className="renewal_notification">
        <Paragraph className="renewal_notification__text" fontSize="body-alt" color="white">
          {content}
        </Paragraph>

        <Link
          href={renewalHref}
          className="renewal_notification__personal_link_container"
          iconplacement="end"
          variant="text-with-icon"
          icon="arrow-right"
          target="_blank"
          color="white"
          rel="noopener"
        >
          Bekijk je persoonlijke aanbod
        </Link>
      </div>
    </>
  );
};
