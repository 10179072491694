import { createApi } from '@reduxjs/toolkit/query/react';
import { contentfulQuery } from './query';
import type { DashboardMessage, DashboardUpdate } from './types';

export const contentfulApi = createApi({
  reducerPath: 'contentful',
  baseQuery: contentfulQuery(),
  endpoints: (builder) => ({
    getDashboardMessage: builder.query<DashboardMessage | undefined, void>({
      query: () => ({
        contentType: 'dashboardMessage',
      }),
      transformResponse: (messages: DashboardMessage[]) => messages.find((message) => message.dashboard === 'Home'),
    }),
    getDashboardUpdates: builder.query<DashboardUpdate[], void>({
      query: () => ({
        contentType: 'webUpdates',
      }),
      transformResponse: (updates: DashboardUpdate[]) => [...updates].sort((a, b) => b.order - a.order),
    }),
  }),
});

export const { useGetDashboardUpdatesQuery, useGetDashboardMessageQuery } = contentfulApi;
